import React from 'react';
import styled from 'styled-components';

const Card: React.FC = ({ children }) => <StyledCard>{children}</StyledCard>;

const StyledCard = styled.div`
  background-color: rgba(5, 5, 5, 0.85);
  color: #ffffff !important;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export default Card;
